import { FC, useCallback, useState } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Getter,
  ColumnFiltersState,
  ColumnDef,
  Row,
} from '@tanstack/react-table'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { TableFilterField } from 'components/tables/TableFilterField'
import { IconButton } from 'components/buttons'
import { useChangeApprovalGroup } from '../../change-approval-group'
import { timeLoggerRouteBuilder } from '@utils/timeLoggerRouteBuilder'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  DownloadCsvButton,
  ScrollableTable,
  Td,
  Th,
  Tr,
} from 'components/tables'
import { UserWithEmployee } from '../types'
import { DateTime } from 'luxon'
import { ValuePicker } from 'components/tables/column-filters'
import { useAuth } from '@hooks/useAuth'

interface Props {
  projectIntegrations: ProjectIntegration[]
  users: UserWithEmployee[]
}

const columnHelper = createColumnHelper<User>()

export const Table: FC<Props> = ({ projectIntegrations, users }) => {
  const { user: signedInUser } = useAuth()
  const [selectedFacilityIds, setSelectedFacilityIds] = useState(
    new Set<number>(),
  )
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'status',
      value: 'active',
    },
  ])

  const { changeApprovalGroup } = useChangeApprovalGroup()

  const signedInUserAdministersFacilityFor = useCallback(
    (user: User) =>
      signedInUser?.administeredFacilities.some(
        ({ id }) => id === user.latestFacility?.id,
      ),
    [signedInUser],
  )
  const signedInUserAdministersAssociatedFacilityFor = useCallback(
    (user: User) =>
      user.associatedFacilityIds.some((id) =>
        signedInUser?.administeredFacilities.some(
          (facility) => facility.id === id,
        ),
      ),
    [signedInUser],
  )

  const integrationColumns = projectIntegrations
    .sort((a, b) => `${a.type} ${a.name}`.localeCompare(`${b.type} ${b.name}`))
    .map((integration) => {
      return {
        id: `integration-${integration.id}`,
        header: t('features.usersAdmin.projectIntegrationUserId', {
          projectIntegrationName: integration.name,
        }),
        filterFn: 'includesString',
        accessorFn: (user: UserWithEmployee) => {
          const integrationLink = user.integrationUserLinks.find(
            (link) => link.projectIntegration.id === integration.id,
          )

          return integrationLink?.sourceUser?.id?.toString()
        },
        cell: ({ getValue }: { getValue: Getter<string> }) =>
          getValue() ?? (
            <div className="text-neutral-300">
              {t('features.usersAdmin.unlinked')}
            </div>
          ),
      } as ColumnDef<UserWithEmployee>
    })

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.usersAdmin.name'),
      filterFn: 'includesString',
      cell: ({ row, getValue }) => {
        // Admins can only access the time logging interface for a given user
        // if they administer a facility they have ever been associated with
        if (signedInUserAdministersAssociatedFacilityFor(row.original)) {
          return (
            <Link
              to={timeLoggerRouteBuilder(row.original.id)}
              className="text-hyperlink-blue"
            >
              {getValue()}
            </Link>
          )
        } else {
          return getValue()
        }
      },
    }),
    columnHelper.accessor('email', {
      header: t('features.usersAdmin.email'),
      filterFn: 'includesString',
    }),
    {
      id: 'WID',
      header: 'WID',
      accessorFn: (user) => user.workdayWorkerId?.toString(),
      filterFn: 'includesString',
    } as ColumnDef<UserWithEmployee>,
    ...integrationColumns,
    {
      id: 'approvalGroup',
      accessorFn: (user: UserWithEmployee) => user.employee?.approvalGroup?.name,
      header: t('common.approvalGroup'),
      filterFn: 'includesString',
      cell: ({
        row,
        getValue,
      }: {
        row: Row<UserWithEmployee>
        getValue: () => string | undefined
      }) => {
        const user = row.original
        const employee = user.employee
        if (!employee?.approvalGroup) return null

        // AG is only accessible for approvals and editable if the user is an admin of the user's latest facility
        if (employee && signedInUserAdministersFacilityFor(user)) {
          return (
            <div className="flex items-center justify-between">
              <Link
                to={`/approvals/${employee.approvalGroup.approvalDomainId}`}
                className="text-hyperlink-blue"
              >
                {getValue()}
              </Link>
              <IconButton
                icon={faEdit}
                className="invisible w-8 h-8 ml-2 border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
                onClick={() => void changeApprovalGroup(employee)}
              />
            </div>
          )
        } else {
          return <div className="flex items-center">{getValue()}</div>
        }
      },
    } as ColumnDef<UserWithEmployee>,
    // TODO: Re-enable once API includes Worker AMGs
    // {
    //   accessorFn: (user: UserWithEmployee) => user.employee?.adminManagedGroup,
    //   header: t('features.usersAdmin.adminManagedGroup'),
    //   filterFn: 'includesString',
    // } as ColumnDef<UserWithEmployee>,
    {
      id: 'facility',
      accessorFn: (user: UserWithEmployee) => user.latestFacility?.name,
      header: t('common.facility'),
      filterFn: 'includesString',
    } as ColumnDef<UserWithEmployee>,
    {
      accessorFn: (user: UserWithEmployee) => user.employee?.hireDate,
      header: t('features.usersAdmin.hireDate'),
      cell: ({ getValue }: { getValue: () => DateTime | undefined }) => {
        const hireDate = getValue()
        if (!hireDate) return null

        return hireDate.toLocaleString(DateTime.DATE_FULL)
      },
    } as ColumnDef<UserWithEmployee>,
    {
      accessorFn: (user: UserWithEmployee) => user.employee?.terminationDate,
      header: t('features.usersAdmin.terminationDate'),
      cell: ({ getValue }: { getValue: () => DateTime | undefined }) => {
        const hireDate = getValue()
        if (!hireDate) return null

        return hireDate.toLocaleString(DateTime.DATE_FULL)
      },
    } as ColumnDef<UserWithEmployee>,
    {
      id: 'status',
      accessorFn: (user: UserWithEmployee) =>
        user.active ? 'active' : 'inactive',
      header: t('features.usersAdmin.status'),
      cell: ({ getValue }: { getValue: () => 'active' | 'inactive' }) => {
        const active = getValue()
        return t(`features.usersAdmin.${active}`).toString()
      },
      filterFn: 'equalsString',
    } as ColumnDef<UserWithEmployee>,
  ]

  const table = useReactTable({
    data: users,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  const facilities = users.reduce<{ id: number; name: string }[]>(
    (acc, user) => {
      if (!user.latestFacility) return acc
      if (acc.find((facility) => facility.id === user.latestFacility?.id))
        return acc

      return [...acc, user.latestFacility]
    },
    [],
  )

  return (
    <div className="pt-6 flex flex-col flex-grow">
      <div className="px-8 flex justify-between mb-2">
        <div className="text-lg">
          {t('common.usersCountAndTotal', {
            count: table.getRowModel().rows.length,
            total: users.length,
          })}
        </div>
        <DownloadCsvButton table={table} filename={t('common.users')} />
      </div>
      <div className="flex flex-col relative flex-grow">
        <ScrollableTable
          header={table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  clickable={header.column.getCanSort()}
                  colSpan={header.colSpan}
                  label={flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  sortDir={header.column.getIsSorted()}
                >
                  {header.id === 'facility' && (
                    <ValuePicker
                      className="mt-3"
                      accessor={(facility) => facility.id}
                      columnNameTranslationKey="common.facilityName"
                      onChange={setSelectedFacilityIds}
                      values={facilities}
                      renderLabel={(facility) => facility.name}
                      selected={selectedFacilityIds}
                    />
                  )}
                  {header.column.getCanFilter() && header.id !== 'facility' && (
                    // adding a width (via inputClassName) here so that the input can be smaller than browser's default -AF
                    <TableFilterField
                      column={header.column}
                      inputClassName="w-20"
                    />
                  )}
                </Th>
              ))}
            </Tr>
          ))}
          body={table.getRowModel().rows.map((row) => (
            <Tr key={row.id} className="hover:bg-neutral-100 group/row">
              {row.getVisibleCells().map((cell) => (
                <Td className="h-16 px-3" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        />
      </div>
    </div>
  )
}
