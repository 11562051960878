import { useMemo, useCallback, useState } from 'react'
import { useKeyboardForGrid } from '@hooks/useKeyboardForGrid'
import AllocationTable from './AllocationTable'
import { useTimeCards } from '@hooks/useTimeCards'
import {
  TotalByDateRow,
  Section,
  SectionHeading,
} from '@features/time-logging/components'
import {
  useSelectedTasks,
  useTaskIdsWithEntries,
} from '@features/time-logging/hooks/useTasks'
import { Button } from 'components/buttons/Button'
import {
  faCalculator,
  faClipboardCheck,
  faStopwatch,
} from '@fortawesome/pro-solid-svg-icons'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import {
  extractSortedProjectsAndProjectIntegrations,
  groupTaskByProjectAndIntegrationId,
  taskGroupKey,
} from '@utils/groupTaskbyProjectAndIntegrationId'
import { EmptyAllocationBanner } from 'components/time-entry-table'
import { ButtonGroup } from 'components/buttons'

interface Props {
  dateRange: DateTime[]
  headerHoverHandler: (date: DateTime | null) => void
  onTaskButtonClick: () => void
  readOnly: boolean
}

export function AllocationTables(props: Props) {
  const { t } = useTranslation()
  const [rightColumn, setRightColumn] = useState<'etc' | 'totals'>('etc')

  const adminProject = useMemo(
    () => ({
      id: 0,
      name: t('common.nonProject'),
      isAdminProject: true,
    }),
    [t],
  )

  const selectedTasksQuery = useSelectedTasks()
  const userId = useUserId()
  const timeCardsQuery = useTimeCards({ userId })
  const taskIdsWithEntries = useTaskIdsWithEntries(userId)

  const loading = useMemo(
    () =>
      selectedTasksQuery.data === undefined ||
      timeCardsQuery.data === undefined,
    [selectedTasksQuery, timeCardsQuery],
  )

  const tasks = useMemo(
    () => selectedTasksQuery.data ?? [],
    [selectedTasksQuery.data],
  )

  const anyTasksSelected = useMemo(() => tasks.length > 0, [tasks])

  const tasksByProjectAndIntegrationId = useMemo(
    () => groupTaskByProjectAndIntegrationId(tasks),
    [tasks],
  )

  const rows = tasks.length
  const columns = props.dateRange.length

  const activeColumns = useMemo(() => {
    const timeCards = timeCardsQuery.data || []
    return props.dateRange.map((d) => {
      if (
        timeCards.find(
          (c) => c.id !== 0 && c.date.hasSame(d, 'day') && d <= DateTime.now(),
        )
      ) {
        return d
      }
    })
  }, [props.dateRange, timeCardsQuery.data])

  const isFocusableCell = useCallback(
    (cell: Cell) => activeColumns[cell.x] !== undefined,
    [activeColumns],
  )

  const { focusCell, focusedCell, moveDown } = useKeyboardForGrid(
    rows,
    columns,
    useMemo(
      () => ({
        enableTabNavigation: true,
        isFocusableCell,
      }),
      [isFocusableCell],
    ),
  )

  // Alphabetical order, admin/non-project project last
  const sortedProjectsAndIntegrations = useMemo(
    () =>
      extractSortedProjectsAndProjectIntegrations(
        tasksByProjectAndIntegrationId,
        adminProject,
      ),
    [tasksByProjectAndIntegrationId, adminProject],
  )

  const rowStart = (index: number) => {
    return sortedProjectsAndIntegrations.reduce(
      (res, projectAndIntegration, tableIndex) => {
        return tableIndex < index
          ? res +
              tasksByProjectAndIntegrationId[
                taskGroupKey(projectAndIntegration)
              ].length
          : res
      },
      0,
    )
  }

  return (
    <Section loading={loading} testId="allocation">
      <div className="flex flex-row items-center space-between">
        <SectionHeading className="mb-0">
          {t('features.timeLogging.allocation')}
        </SectionHeading>
        <ButtonGroup
          className="ml-auto"
          buttons={[
            {
              selected: rightColumn === 'etc',
              icon: faCalculator,
              onClick: () => setRightColumn('etc'),
              tooltip: t('features.timeLogging.estimates').toString(),
            },
            {
              selected: rightColumn === 'totals',
              icon: faStopwatch,
              onClick: () => setRightColumn('totals'),
              tooltip: t('features.timeLogging.timeLoggedThisWeek').toString(),
            },
          ]}
        />
      </div>
      {anyTasksSelected ? (
        sortedProjectsAndIntegrations.map(
          ({ project, projectIntegration }, index) => (
            <AllocationTable
              taskIdsWithEntries={taskIdsWithEntries}
              focusedCell={focusedCell}
              headerHoverHandler={props.headerHoverHandler}
              key={`project-${project.id}-${projectIntegration?.id ?? 0}`}
              onCellFocus={focusCell}
              onCellSaveViaEnter={moveDown}
              project={project}
              projectIntegration={projectIntegration}
              readOnly={props.readOnly}
              rowStart={rowStart(index)}
              show={rightColumn}
              tasks={
                tasksByProjectAndIntegrationId[
                  taskGroupKey({ project, projectIntegration })
                ]
              }
            />
          ),
        )
      ) : (
        <EmptyAllocationBanner
          body={t(
            'features.timeLogging.clickTheSelectTasksButtonToBeginSelectingTasks',
          )}
          button={
            <Button
              variant="outlined"
              className="mt-6 text-primary-600 border-primary-600 border-[1.5px]"
              icon={faClipboardCheck}
              onClick={props.onTaskButtonClick}
            >
              {t('features.timeLogging.selectTasksHeading')}
            </Button>
          }
        />
      )}

      {anyTasksSelected && (
        <TotalByDateRow
          dateRange={props.dateRange}
          hideTotal={rightColumn === 'etc'}
        />
      )}
    </Section>
  )
}
