import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getMe, login as exchangeGoogleJwtForCookie, logout } from '@lib/api'
import { axiosClient } from '@lib/axios'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { googleLogout } from '@react-oauth/google'

const registerUserWithSentry = (user: Me) => {
  Sentry.setUser({
    id: user.id.toString(),
    username: `${user.firstName} ${user.lastName}`,
  })
}

const clearRegisteredSentryUser = () => {
  Sentry.setUser(null)
}

interface Properties {
  initialized: boolean
  user: Me | null
}

interface Methods {
  login: (jwt: string) => Promise<void>
  signOut: () => Promise<void>
}

type AuthContext = Properties & Methods

const initialContext: AuthContext = {
  initialized: false,
  user: null,
  login: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
}

export const AuthContext = createContext(initialContext)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const [user, setUser] = useState<Properties['user']>(null)

  const getCurrentUserDetails = async (): Promise<void> => {
    try {
      const response = await getMe()
      const me = response.data

      setInitialized(true)

      if (!me)
        throw new Error(
          `Me is undefined, despite getting a successful response from the server.  response: ${JSON.stringify(
            response,
          )}`,
        )

      // Hoping to debug: https://storiedsoftware.sentry.io/issues/6092129274/events/latest/?project=4507948472467456&statsPeriod=7d
      if (!me.firstName) {
        Sentry.captureMessage(
          `Me firstname is not defined within AuthProvider.tsx.  me: ${JSON.stringify(
            me,
          )}`,
        )
      }

      setUser({ ...me, profilePictureUrl: me.profilePictureUrl ?? '' })
    } catch (e) {
      /**
       * Throw all errors other than when the response is 401 in hopes of
       * debugging: https://storiedsoftware.sentry.io/issues/6092129274/events/latest/?project=4507948472467456&statsPeriod=7d
       *
       * I don't think this'll be too chatty, but if it is, we can always remove it.
       *
       * -AF 1/7/2025
       */
      if (!(e instanceof AxiosError && e.response?.status === 401))
        Sentry.captureException(e)

      setInitialized(true)
    }
  }

  useEffect(() => {
    if (user) {
      registerUserWithSentry(user)
    } else {
      clearRegisteredSentryUser()
    }

    // Reminder: returned function within useEffect gets called on each dependency change
    return clearRegisteredSentryUser
  }, [user])

  useEffect(() => void getCurrentUserDetails(), [])

  useEffect(() => {
    const id = axiosClient.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) setUser(null)

        return Promise.reject(error)
      },
    )

    return () => axiosClient.interceptors.response.eject(id)
  })

  const login = useCallback<Methods['login']>(async (jwt) => {
    try {
      await exchangeGoogleJwtForCookie(jwt)
      await getCurrentUserDetails()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  const signOut = useCallback<Methods['signOut']>(async () => {
    try {
      await logout()

      if (import.meta.env.VITE_AUTH_MECHANISM !== 'oidc') {
        googleLogout() // https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out
      }

      setUser(null)
    } catch (e) {
      Sentry.captureException(e)
      // Even if the logout() call fails, I think we still want to clear the user, so they can reauthenticate?
      setUser(null)
    }
  }, [])

  const contextValue: AuthContext = useMemo(
    () => ({
      initialized,
      user,
      login,
      signOut,
    }),
    [user, login, signOut, initialized],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
