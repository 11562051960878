import { FC } from 'react'
import { secondsToHHMM } from 'utils/time'
import { Annotation } from './Annotation'

interface Props {
  label: string
  onClick: () => void
  totalSeconds: number
  variant: 'floating' | 'attachment'
}

export const TimeOff: FC<Props> = ({
  label,
  onClick,
  totalSeconds,
  variant,
}) => {
  return (
    <Annotation
      onClick={onClick}
      position={variant}
      tooltipContent={label}
      type="timeOff"
    >
      <span className="truncate">{label}</span>
      <span className="font-semibold ml-1">{secondsToHHMM(totalSeconds)}</span>
    </Annotation>
  )
}
