import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { taskKeys } from '@lib/keys'
import { useUserId } from './useUserId'
import {
  deselectTask,
  getSelectedTasks,
  getAssignedTasks,
  selectTask,
  getNonProjectTasks,
} from '@lib/api'
import { useWeekDates } from '@hooks/useWeekDates'
import { useMemo } from 'react'
import { useEntries } from '@hooks/useEntries'

export const useAssignedTasksQuery = ({ enabled } = { enabled: true }) => {
  const userId = useUserId()

  return useQuery(taskKeys.assigned(userId), () => getAssignedTasks(userId), {
    enabled,
  })
}

export const useSelectedTasks = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useQuery(taskKeys.selected(userId, start, end), () =>
    getSelectedTasks(userId, start, end),
  )
}

export const useSelectTask = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()
  const queryClient = useQueryClient()

  return useMutation(
    (task: SelectedTask) => {
      return selectTask(userId, start, task.id)
    },
    {
      onMutate: async (task) => {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(taskKeys.selected(userId, start, end))

        // snapshot the previous value
        const previousTasks = queryClient.getQueryData<SelectedTask[]>(
          taskKeys.selected(userId, start, end),
        )

        // optimistically update to the new value
        if (previousTasks) {
          queryClient.setQueryData(taskKeys.selected(userId, start, end), [
            ...previousTasks,
            task,
          ])
        }

        // Return a context object with the snapshotted value
        return { previousTasks }
      },
      onError: (_err, _task, context) => {
        queryClient.setQueryData(
          taskKeys.selected(userId, start, end),
          context?.previousTasks,
        )
      },
      onSettled: () => {
        void queryClient.invalidateQueries(
          taskKeys.selected(userId, start, end),
        )
        void queryClient.invalidateQueries(taskKeys.nonProject)
      },
    },
  )
}

export const useDeselectTask = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()
  const queryClient = useQueryClient()

  return useMutation(
    (task: SelectedTask) => {
      return deselectTask(userId, start, task.id)
    },
    {
      onMutate: async (task) => {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(taskKeys.selected(userId, start, end))

        // snapshot the previous value
        const previousTasks = queryClient.getQueryData<SelectedTask[]>(
          taskKeys.selected(userId, start, end),
        )

        // optimistically update to the new value
        if (previousTasks) {
          queryClient.setQueryData(
            taskKeys.selected(userId, start, end),
            previousTasks.filter((t) => t.id !== task.id),
          )
        }

        // Return a context object with the snapshotted value
        return { previousTasks }
      },
      onError: (_err, _task, context) => {
        queryClient.setQueryData(
          taskKeys.selected(userId, start, end),
          context?.previousTasks,
        )
      },
      onSettled: () => {
        void queryClient.invalidateQueries(
          taskKeys.selected(userId, start, end),
        )
        void queryClient.invalidateQueries(taskKeys.nonProject)
      },
    },
  )
}

export const useNonProjectTasks = ({ enabled } = { enabled: true }) => {
  return useQuery(taskKeys.nonProject, getNonProjectTasks, { enabled })
}

export const useTaskIdsWithEntries = (userId: number) => {
  const entriesQuery = useEntries({ userId })

  const taskIdsWithEntries = useMemo(() => {
    const taskIds = new Set<number>()
    const entries = entriesQuery.data ?? []

    entries.forEach((entry) => {
      /**
       * As of this writing, entries are 'deleted' by reducing the duration to 0.
       *
       * - AF 11/28/22
       */
      if (entry.duration > 0) taskIds.add(entry.task.id)
    })

    return taskIds
  }, [entriesQuery.data])

  return taskIdsWithEntries
}
