const isBasicWorker = (
  worker: TWorker | BasicWorker,
): worker is BasicWorker => {
  return 'name' in worker && 'adminManagedGroupId' in worker
}

export const toBasicWorker = (worker: TWorker | BasicWorker): BasicWorker => {
  if (isBasicWorker(worker)) return worker

  return {
    workdayWorkerId: worker.workdayWorkerId,
    name: worker.fullName,
    user: {
      id: worker.user.id,
      email: worker.user.email,
    },
    approvalGroup: worker.approvalGroup,
    adminManagedGroupId: worker.adminManagedGroup?.id,
  }
}
