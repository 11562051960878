import { Header } from '../components/Header'
import { useUsers } from '@hooks/useUsers'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'
import { useProjectIntegrations } from '@hooks/useProjectIntegrations'
import { useEmployees } from '@hooks/useEmployee'

export const UsersAdmin = () => {
  const { data: users } = useUsers()
  const { data: employees } = useEmployees()
  const { data: projectIntegrations } = useProjectIntegrations()

  const usersWithEmployees = users?.map((user) => {
    return {
      ...user,
      employee: employees?.find((employee) => employee.user.id === user.id),
    }
  })

  return (
    <div className="flex flex-col flex-grow">
      <Header />

      {usersWithEmployees && users && employees && projectIntegrations ? (
        <Table
          projectIntegrations={projectIntegrations}
          users={usersWithEmployees}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}