import { Spinner } from 'components/loaders'
import { Timeline } from 'components/timeline'
import { FC, Fragment } from 'react'
import { TaskEstimateMode } from 'types'
import { EtcCardFooter } from './EtcCardFooter'
import { useTaskEstimates as useTaskEstimatesOriginal } from 'hooks/useTaskEstimates'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCircleQuestion } from '@fortawesome/pro-solid-svg-icons'
import { isNullTaskEstimate } from '@utils/isNullTaskEstimate'

interface Props {
  estimated?: number // seconds
  estimateMode: TaskEstimateMode
  hidden: boolean
  onHistoryClose: () => void
  taskId: number
  useTaskEstimates?: (
    taskId: number,
    options?: { enabled: boolean },
  ) => {
    isLoading: boolean
    data: TaskEstimate[] | undefined
  }
}

export const EtcHistory: FC<Props> = ({
  estimated,
  estimateMode,
  hidden,
  onHistoryClose,
  taskId,
  useTaskEstimates = useTaskEstimatesOriginal,
}) => {
  const { isLoading, data: taskEstimates = [] } = useTaskEstimates(taskId, {
    enabled: !hidden,
  })
  const { t } = useTranslation()

  if (hidden) return null

  const itemsByYear = new Map<number, TimelineItem[]>()

  taskEstimates
    .map((taskEstimate) => {
      return {
        title: t('components.estimatedTimeToCompletion.createdBy', {
          name: taskEstimate.creatorName,
        }),
        date: taskEstimate.createdAt,
        subtext: isNullTaskEstimate(taskEstimate)
          ? t('components.estimatedTimeToCompletion.estimateRemoved')
          : t(
              `components.estimatedTimeToCompletion.${taskEstimate.suppliedValueType}Changed`,
              {
                count: taskEstimate.suppliedValue / 60 / 60 / 8,
              },
            ),
      }
    })
    .sort((a, b) => (b.date.toMillis() - a.date.toMillis() > 0 ? 1 : -1))
    .forEach((item) => {
      const year = item.date.get('year')
      const group = itemsByYear.get(year) ?? []
      itemsByYear.set(year, [...group, item])
    })

  const historyGroupedByYear = Array.from(itemsByYear.entries())

  return (
    <>
      <div className="flex flex-col max-h-[374px] p-4 overflow-y-auto relative">
        {isLoading ? (
          <Spinner className="mx-auto" />
        ) : historyGroupedByYear.length === 0 ? (
          <div className="flex flex-row p-4 items-center text-xs border border-neutral-200 bg-neutral-50 rounded text-neutral-500">
            <FontAwesomeIcon
              className="text-neutral-400 mr-4"
              icon={faFileCircleQuestion}
            />
            {t('components.estimatedTimeToCompletion.noEstimateHistoryForTask')}
          </div>
        ) : (
          historyGroupedByYear.map(([year, data]) => (
            <Fragment key={year}>
              <div className="flex flex-row items-center justify-between text-neutral-900">
                <span className="font-medium">{year}</span>
              </div>
              <Timeline timeline={data} className="px-4" />
            </Fragment>
          ))
        )}
      </div>
      <EtcCardFooter
        showDisabledEtcMessage={estimateMode !== TaskEstimateMode.FULL}
        historyShown={true}
        onHistoryClick={onHistoryClose}
        warning={
          estimateMode !== TaskEstimateMode.FULL && estimated === undefined
        }
      />
    </>
  )
}
