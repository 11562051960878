import { Button } from 'components/buttons'
import { Splash } from 'components/layout'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

export const LoginError: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const supportUrl = import.meta.env.VITE_TIME_LOGGER_SUPPORT_URL

  const code = searchParams.get('error_code')
  const errorMessage = searchParams.get('oidc_error_message')

  const message = ((code: string) => {
    const c = code.toLowerCase()

    if (c === 'unauthorized')
      return t('features.loginError.youAreNotAuthorizedToAccessTimeLogger')
    if (c === 'oidc_failure' && errorMessage)
      return (
        <div className="flex flex-col">
          {t('features.loginError.theAuthenticationProviderReturnedAnError')}
          <code className="font-bold">{errorMessage}</code>
        </div>
      )
    if (c === 'oidc_failure' && !errorMessage)
      return t(
        'features.loginError.theAuthenticationProviderExperiencedAnUnknownError',
      )

    return t('features.loginError.somethingWentWrong')
  })(code ?? '')

  return (
    <Splash
      heading={t('features.loginError.weCouldNotSignYouIn')}
      subHeading={message}
    >
      <div className="flex flex-col w-full">
        <h3 className="font-semibold text-neutral-700">
          {t('features.loginError.needHelp')}
        </h3>
        <a
          className="text-sm text-hyperlink-blue"
          href={supportUrl}
          rel="noreferrer"
          target="_blank"
        >
          {import.meta.env.VITE_TIME_LOGGER_SUPPORT_LABEL}
        </a>
        <Link to="/login" className="mt-6">
          <Button className="h-11 text-lg w-full">
            {t('features.loginError.returnToLogin')}
          </Button>
        </Link>
      </div>
    </Splash>
  )
}
