import { FC, useState } from 'react'
import { Tooltip } from '@lib/tooltip'
import { IconButton, MenuButton } from 'components/buttons'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons'
import { useDeselectTask } from '../../../hooks/useTasks'
import { useTranslation } from 'react-i18next'

interface Props {
  task: SelectedTask
  taskIdsWithEntries: Set<number>
}

export const TaskCell: FC<Props> = ({ task, taskIdsWithEntries }) => {
  const { t } = useTranslation()
  const pathString = task.displayPath.join(' • ')
  const deselectTask = useDeselectTask()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [dropdownIsHidden, setDropdownIsHidden] = useState(true)

  const removeTaskIsDisabled = taskIdsWithEntries.has(task.id)

  const tooltip = removeTaskIsDisabled
    ? t(
        'features.timeLogging.taskSelection.taskCannotBeRemovedWhenTimeIsAllocatedToIt',
      )
    : undefined

  return (
    <div
      className="grow grid grid-cols-2 gap-2 text-xs group"
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className="flex flex-row items-center font-medium text-neutral-600 text-ellipsis overflow-hidden whitespace-nowrap"
        data-tooltip-id={`selected-task-cell-task-path-${task.id}`}
      >
        <Tooltip
          id={`selected-task-cell-task-path-${task.id}`}
          delayShow={1000}
        >
          {pathString}
        </Tooltip>

        {pathString}
      </div>
      <div className="flex flex-row items-center justify-between font-semibold text-neutral-900">
        <span
          className="text-ellipsis overflow-hidden whitespace-nowrap"
          data-tooltip-id={`selected-task-cell-task-name-${task.id}`}
        >
          <Tooltip
            id={`selected-task-cell-task-name-${task.id}`}
            delayShow={1000}
          >
            {task.name}
          </Tooltip>
          {task.name}
        </span>
        {(isMouseOver || !dropdownIsHidden) && (
          <MenuButton
            button={({ disabled, toggleMenuHidden }) => (
              <IconButton
                icon={faEllipsisV}
                onClick={toggleMenuHidden}
                disabled={disabled}
              />
            )}
            menuItems={[
              {
                label: 'Remove Task',
                onClick: () => deselectTask.mutate(task),
                disabled: removeTaskIsDisabled,
                tooltip,
              },
            ]}
            onMenuHiddenChange={setDropdownIsHidden}
          />
        )}
      </div>
    </div>
  )
}
