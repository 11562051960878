import { FC } from 'react'
import { col } from '@utils/styles'
import { useEntriesByTimeCard } from '@hooks/useEntries'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import classNames from 'classnames'
import {
  getTitleKey,
  timeCardValidForSubmission,
  totalEntriesDuration,
} from '@utils/timeCards'
import { Header } from './time-card/Header'
import { Body } from './time-card/Body'
import { Footer } from './time-card/Footer'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

interface Props {
  numberLabel: number
  timeCard: TimeCard
  className?: string
  totalTimeOffInSeconds: number
  readonly: boolean
  hasLeave: boolean
  lock?: DayLock
  scheduledWorkHours?: string // undefined == not scheduled to work
}

const TimeCard: FC<Props> = ({
  numberLabel,
  timeCard,
  className,
  totalTimeOffInSeconds,
  hasLeave,
  lock,
  scheduledWorkHours,
}) => {
  const { t } = useTranslation()
  const userId = useUserId()
  const entries = useEntriesByTimeCard(timeCard.id, userId)

  const totalAllocatedInSeconds = totalEntriesDuration(entries)

  const inFuture = timeCard.date > DateTime.now()

  const isValid = timeCardValidForSubmission({
    timeCard: timeCard,
    entries,
    totalTimeOffInSeconds: totalTimeOffInSeconds,
    onLeave: hasLeave,
    scheduledWorkHours,
  })

  return (
    <div
      className={classNames(
        col(timeCard.date),
        'flex flex-col select-none overflow-hidden min-h-[121px border-none',
        className,
      )}
      title={t(getTitleKey(timeCard))}
    >
      <Header
        numberLabel={numberLabel}
        timeCard={timeCard}
        className={classNames('border border-b-0 rounded-t', {
          'bg-neutral-100 border-neutral-300': !timeCard.submitted && !isValid,
          'bg-success-700 border-success-700 text-white':
            isValid && !timeCard.submitted,
          'bg-primary-500 border-primary-500 text-white': timeCard.submitted,
        })}
      />

      <div
        className={classNames(
          'flex flex-col overflow-hidden rounded-b border border-t-0 grow',
          {
            'border-neutral-300': !timeCard.submitted && !isValid,
            'bg-neutral-50': inFuture,
            'bg-success-100 border-success-300': isValid && !timeCard.submitted,
            'bg-primary-100 border-primary-300': timeCard.submitted,
          },
        )}
      >
        <Body
          timeCard={timeCard}
          inFuture={inFuture}
          totalAllocatedInSeconds={totalAllocatedInSeconds}
          isValid={isValid}
          className={classNames({
            'border-b border-dashed': isValid || timeCard.submitted,
            'border-success-300': isValid,
            'border-primary-300 text-neutral-900': timeCard.submitted,
          })}
          scheduledWorkHours={scheduledWorkHours}
        />

        <Footer
          timeCard={timeCard}
          isValid={isValid}
          inFuture={inFuture}
          className={classNames({
            'bg-neutral-100':
              !timeCard.submitted &&
              !timeCard.sentToPayrollAt &&
              (inFuture || !isValid),
          })}
          lock={lock}
        />
      </div>
    </div>
  )
}

export default TimeCard
