import { ProjectIntegrationType } from 'types'
import { Item } from './types'

export const itemFromtask = (
  task: Task,
  disabledTaskIds: Set<number>,
  selectedTaskIds: Set<number>,
): Item => ({
  task,
  disabled: disabledTaskIds.has(task.id) && selectedTaskIds.has(task.id),
  checked: selectedTaskIds.has(task.id),
})

export const itemsFromTasks = (
  tasks: Task[],
  disabledTaskIds: Set<number>,
  selectedTaskIds: Set<number>,
): Item[] => {
  return tasks.map((task) =>
    itemFromtask(task, disabledTaskIds, selectedTaskIds),
  )
}

export const includes = (search: string) => (string: string | null) => {
  return string?.toLowerCase()?.includes(search)
}

export const findItem = (items: Item[], taskId: number) => {
  const item = items.find(({ task }) => task.id === taskId)

  if (!item) throw new Error(`Unreachable: Task with id ${taskId} not found`)

  return item
}

export const isShotgridTaskSourceDetails = (
  sourceDetails: ShotgridTaskSourceDetails | JiraTaskSourceDetails,
): sourceDetails is ShotgridTaskSourceDetails => {
  return sourceDetails.type === ProjectIntegrationType.ShotGrid
}

export const isJiraTaskSourceDetails = (
  sourceDetails: ShotgridTaskSourceDetails | JiraTaskSourceDetails,
): sourceDetails is JiraTaskSourceDetails => {
  return sourceDetails.type === ProjectIntegrationType.Jira
}
