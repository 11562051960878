import { useState } from 'react'
import { AllocationTableHeader, Table } from 'components/time-entry-table'
import {
  EntriesGrid,
  TaskNameColumn,
  TaskTotalColumn,
} from '@features/time-logging/components'
import { EtcColumn } from '../entries-table/EtcColumn'

interface Props {
  taskIdsWithEntries: Set<number>
  focusedCell: Cell
  headerHoverHandler: HeaderHoverHandler
  onCellFocus: (cell: Cell) => void
  onCellSaveViaEnter: () => void
  project: Project
  projectIntegration?: ProjectIntegration
  readOnly: boolean
  rowStart: number
  show: 'etc' | 'totals'
  tasks: SelectedTask[]
}

function AllocationTable(props: Props) {
  const [hoverY, setHoverY] = useState(0)
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="mt-2">
      <AllocationTableHeader
        expanded={expanded}
        onExpandChange={setExpanded}
        projectName={props.project.name}
        projectIntegrationCode={props.projectIntegration?.code}
      />
      {expanded && (
        <Table key={`project_table_${props.project.id}`}>
          <TaskNameColumn
            taskIdsWithEntries={props.taskIdsWithEntries}
            hoverY={hoverY}
            tasks={props.tasks}
          />
          <EntriesGrid
            project={props.project}
            tasks={props.tasks}
            headerHoverHandler={props.headerHoverHandler}
            yHoverHandler={setHoverY}
            focusedCell={props.focusedCell}
            rowStart={props.rowStart}
            onSaveViaEnter={props.onCellSaveViaEnter}
            onCellFocus={props.onCellFocus}
            readOnly={props.readOnly}
          />
          {props.show === 'totals' && <TaskTotalColumn tasks={props.tasks} />}
          {props.show === 'etc' && <EtcColumn tasks={props.tasks} />}
        </Table>
      )}
    </div>
  )
}

export default AllocationTable
