import { FC, ReactNode, useId } from 'react'
import { Tooltip } from 'react-tooltip'
import { twJoin } from 'tailwind-merge'

interface Props {
  children: ReactNode
  onClick?: () => void
  position: 'floating' | 'attachment'
  tooltipContent?: string
  type: 'timeOff' | 'holiday'
}

export const Annotation: FC<Props> = ({
  children,
  onClick,
  position,
  tooltipContent,
  type,
}) => {
  const id = useId()

  return (
    <>
      <div
        className={twJoin(
          'border rounded-t text-xs mx-2 py-1 px-4 flex items-center justify-center flex-nowrap',
          type === 'timeOff' &&
            'bg-purple-100 border-purple-300 text-purple-800',
          type === 'holiday' && 'bg-teal-100 border-teal-300 text-teal-800',
          position === 'attachment' && 'border-b-0',
          position === 'floating' && 'rounded-b',
          onClick && 'cursor-pointer',
        )}
        data-tooltip-id={id}
        onClick={onClick}
      >
        {children}
      </div>
      <Tooltip id={id} place="bottom" delayShow={500}>
        {tooltipContent}
      </Tooltip>
    </>
  )
}
