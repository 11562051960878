import { faGear } from '@fortawesome/pro-regular-svg-icons'
import {
  faBusinessTime,
  faStopwatch,
  faCheckDouble,
  faDatabase,
} from '@fortawesome/pro-solid-svg-icons'
import { useAuth } from '@hooks/useAuth'
import { useModal } from '@hooks/useModal'
import { Sidebar } from 'components/navigation'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  hidden: boolean
  onClose: () => void
}

export const NavigationSidebar: FC<Props> = ({ hidden, onClose }) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const currentPath = useLocation().pathname
  const navigate = useNavigate()
  const modal = useModal()

  const timeLoggerNavItems = useMemo(() => {
    const navItems = []

    navItems.push({
      label: t('common.timeLogging'),
      path: '/time-logger',
      icon: faStopwatch,
      active: currentPath.startsWith('/time-logger'),
    })

    if (user?.hasApproverRole) {
      navItems.push({
        label: t('common.approvals'),
        path: '/approvals',
        icon: faCheckDouble,
        active: currentPath.startsWith('/approvals'),
      })
    }

    if (user?.administeredFacilities.length) {
      const adminNavItems = [
        {
          label: t('features.navigationSidebar.timeCardAdmin'),
          path: '/admin/time-card-admin',
          active: currentPath.startsWith('/admin/time-card-admin'),
        },
        {
          label: t('features.navigationSidebar.approvalsAdmin'),
          path: '/approvals-admin',
          active: currentPath.startsWith('/approvals-admin'),
        },

        {
          label: t('common.projects'),
          path: '/projects',
          active: currentPath.startsWith('/projects'),
        },
        {
          label: t('common.approvalGroups'),
          path: '/approval-groups',
          active: currentPath.startsWith('/approval-groups'),
        },
        {
          label: t('features.navigationSidebar.employees'),
          path: '/employees',
          active: currentPath.startsWith('/employees'),
        },
        {
          label: t('features.navigationSidebar.users'),
          path: '/users',
          active: currentPath.startsWith('/users'),
        },
        {
          label: t('common.facilities'),
          path: '/admin/facilities',
          active: currentPath.startsWith('/admin/facilities'),
        },
        {
          label: t('features.adminManagedGroups.managedGroups'),
          path: '/admin/managed-groups',
          active: currentPath.startsWith('/admin/managed-groups'),
        },
      ]
      navItems.push({
        label: t('features.navigationSidebar.admin'),
        icon: faBusinessTime,
        navItems: adminNavItems,
      })
    }

    const reportingNavItems = []

    // Time Log Reporting is available
    // to all users who hold the role
    if (user?.hasTimeLogReportingRole) {
      reportingNavItems.push({
        label: t('features.reporting.timeLog.timeLogReports'),
        path: '/reporting/time_log_reports',
        active: currentPath.startsWith('/reporting/time_log_reports'),
      })
    }

    // All admins can access audit log reports
    if (user?.administeredFacilities.length) {
      reportingNavItems.push({
        label: t('features.reporting.auditLog.auditLogReports'),
        path: '/reporting/audit_log_reports',
        active: currentPath.startsWith('/reporting/audit_log_reports'),
      })
    }

    if (reportingNavItems.length) {
      navItems.push({
        label: t('features.navigationSidebar.reporting'),
        icon: faDatabase,
        navItems: reportingNavItems,
      })
    }

    return navItems
  }, [user, currentPath, t])

  const sections = useMemo(() => {
    return [
      {
        label: t('common.timeLogger'),
        navItems: timeLoggerNavItems,
      },
      {
        label: t('features.navigationSidebar.system'),
        navItems: [
          {
            label: t('features.navigationSidebar.settings'),
            icon: faGear,
            navItems: [
              {
                label: t('features.settings.global.global'),
                path: '/settings/global',
                active: currentPath.startsWith('/settings/global'),
              },
              {
                label: t('common.timeLogging'),
                path: '/settings/time-logging',
                active: currentPath.startsWith('/settings/time-logging'),
              },
            ],
          },
        ],
      },
    ]
  }, [currentPath, timeLoggerNavItems, t])

  const openSupportModal = useCallback(() => {
    const id = modal.open({
      variant: 'informational',
      closeOnBackgroundClick: true,
      content: (
        <div className="flex flex-col [&>a]:text-hyperlink-blue text-left mt-2">
          <span className="font-medium text-neutral-900">
            {t('common.timeLoggerIssuesAndBugReports')}
          </span>
          <a
            href={import.meta.env.VITE_TIME_LOGGER_SUPPORT_URL}
            target="_blank"
            rel="noreferrer"
          >
            {import.meta.env.VITE_TIME_LOGGER_SUPPORT_LABEL}
          </a>
          {import.meta.env.VITE_PAYROLL_SUPPORT_URL && (
            <>
              <span className="mt-8 font-medium text-neutral-900">
                {t('common.payrollIssues')}
              </span>

              <a href={import.meta.env.VITE_PAYROLL_SUPPORT_URL}>
                {import.meta.env.VITE_PAYROLL_SUPPORT_LABEL}
              </a>
            </>
          )}
        </div>
      ),
      onClose: () => modal.close(id),
      title: t('components.navigation.sidebar.contactSupport'),
    })
  }, [modal, t])

  const handleNavigation = (path: string) => {
    onClose()
    navigate(path)
  }

  return (
    <Sidebar
      hidden={hidden}
      onClose={onClose}
      onContactSupport={openSupportModal}
      onNavigate={handleNavigation}
      sections={sections}
    />
  )
}
