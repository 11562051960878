import { useEntriesSumByWeek } from '@features/time-logging/hooks/useEntrySums'
import { useAbsences } from '../../../hooks/useAbsences'
import { useEmployee } from '@hooks/useEmployee'
import { DateTime, Interval } from 'luxon'
import { useWeekDates } from '@hooks/useWeekDates'
import { useUserId } from './useUserId'
import { useHolidaysByCalendar } from '@hooks/useHolidays'
import { isTimeOff } from '@utils/absence'
import { getDayOfWeek } from '@utils/dates'

export const useTotalsForWeek = (
  startDateOverride?: DateTime,
  endDateOverride?: DateTime,
) => {
  const { start: startDate, end: endDate } = useWeekDates()
  const start = startDateOverride ?? startDate
  const end = endDateOverride ?? endDate

  const userId = useUserId()
  const employeeQuery = useEmployee({ userId, startDateOverride: start })
  const absencesQuery = useAbsences({
    userId,
    startDateOverride: start,
    endDateOverride: end,
  })
  const holidayQuery = useHolidaysByCalendar({
    startDateOverride: start,
    endDateOverride: end,
    holidayCalendarId: employeeQuery.data?.holidayCalendarId,
  })
  const allocatedSeconds = useEntriesSumByWeek(start, end)

  const absenceSeconds =
    absencesQuery.data?.reduce((total, absence) => {
      // Time Off absence seconds are just their individual duration
      if (isTimeOff(absence)) return total + absence.duration

      // Leave absence seconds are calculated
      // as the total scheduled seconds for each day
      // of leave in the given week
      const weekInterval = Interval.fromDateTimes(start, end.endOf('day'))
      const leaveInterval = Interval.fromDateTimes(
        absence.startDate,
        absence.estimatedEndDate.endOf('day'),
      )

      // Portion of leave that intersects the given week
      const intersection = weekInterval.intersection(leaveInterval)

      if (intersection && employeeQuery.data) {
        // Sum of scheduled work hours for each day of leave
        return (
          total +
          intersection.splitBy({ days: 1 }).reduce((sum, interval) => {
            const scheduledWorkHours =
              employeeQuery.data.workSchedule[getDayOfWeek(interval.start)]

            // Leave day does not count towards total if user is not scheduled to work
            if (scheduledWorkHours === undefined) return sum

            return sum + parseFloat(scheduledWorkHours) * 60 ** 2
          }, 0)
        )
      } else {
        // Leave does not intersect the given week or there
        // is no employee data for some reason (which means no work schedule)
        return total
      }
    }, 0) ?? 0

  const dayOfWeekWithHolidays =
    holidayQuery.data?.reduce((acc, holiday) => {
      const days = Interval.fromDateTimes(
        holiday.startDate,
        holiday.endDate.endOf('day'),
      ).splitBy({
        days: 1,
      })

      for (const day of days) {
        acc.add(getDayOfWeek(day.start))
      }

      return acc
    }, new Set<DayOfWeek>()) ?? new Set()

  const holidaySeconds = Array.from(dayOfWeekWithHolidays).reduce(
    (sum, dayOfWeek) => {
      return (
        sum +
        parseFloat(employeeQuery.data?.workSchedule[dayOfWeek] ?? '0') * 60 ** 2
      )
    },
    0,
  )

  const totalSeconds = absenceSeconds + holidaySeconds + allocatedSeconds

  return {
    absenceSeconds,
    holidaySeconds,
    allocatedSeconds,
    totalSeconds,
  }
}
