import { FC, useState, useMemo } from 'react'
import { SearchSelection } from 'components/form/search-selection'
import { useApprovalGroups } from '@hooks/useApprovalGroups'
import { Radio } from 'components/form/radio/Radio'
import { twMerge } from '@lib/tailwind-merge'
import { useTranslation } from 'react-i18next'
import { Employee } from '../types'

type ApprovalGroupSelection = Employee['approvalGroup']

interface Props {
  selected: ApprovalGroupSelection
  onChange: (newApprovalGroup: ApprovalGroupSelection) => void
}

const ApprovalGroupItem = ({
  approvalGroup,
  selected,
  onClick,
}: {
  approvalGroup: ApprovalGroup
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div
      className={twMerge(
        'flex gap-2 text-sm my-2 items-center cursor-pointer',
        selected && 'font-semibold',
      )}
      onClick={onClick}
    >
      <Radio checked={selected} readOnly /> {approvalGroup.name}
    </div>
  )
}

export const ApprovalGroupSelector: FC<Props> = ({ selected, onChange }) => {
  const [search, setSearch] = useState('')

  const {
    data: approvalGroups,
    isFetching: isFetchingApprovalGroups,
    isLoading: isLoadingApprovalGroups,
  } = useApprovalGroups()

  const filteredApprovalGroups = useMemo(() => {
    if (approvalGroups === undefined) return []

    return approvalGroups.filter((approvalGroup) =>
      approvalGroup.name.toLowerCase().includes(search.toLowerCase()),
    )
  }, [approvalGroups, search])

  const { t } = useTranslation()

  return (
    <SearchSelection
      expectedItemHeight={40}
      items={filteredApprovalGroups}
      isFetching={isFetchingApprovalGroups}
      isInitiallyLoading={isLoadingApprovalGroups}
      label={t('features.changeApprovalGroup.changeApprovalGroup')}
      onSearch={setSearch}
      placeholder={t('features.changeApprovalGroup.searchByGroupName')}
      renderItem={(approvalGroup) => (
        <ApprovalGroupItem
          approvalGroup={approvalGroup}
          selected={approvalGroup.id === selected.id}
          onClick={() => onChange(approvalGroup)}
        />
      )}
      totalItems={filteredApprovalGroups.length}
      value={search}
      variant="fixed-below"
      className="max-h-[300px] bg-neutral-50 rounded-lg p-4 pb-0"
      searchFieldClassName="bg-white mb-2"
      searchFieldWrapperClassName="bg-transparent"
    />
  )
}
