import { FC, ReactNode } from 'react'
import { useTaggedTime } from '@hooks/useTaggedTime'
import { useTranslation } from 'react-i18next'
import { createTotalByTagRenderObj } from '@utils/taggedTime'
import { TooltipTable } from 'components/indicators/tooltip-table'
import { Tooltip } from 'react-tooltip'

interface Props {
  extraData: Map<ReactNode, ReactNode>
  id: string
  showRegularHoursTooltip: boolean
  timeCardId?: number
}

export const ApprovalTooltip: FC<Props> = ({
  extraData,
  id,
  showRegularHoursTooltip,
  timeCardId,
}) => {
  const { data: taggedTime } = useTaggedTime(timeCardId)
  const { t } = useTranslation()

  const tableData = new Map<ReactNode, ReactNode>([...extraData])

  if (taggedTime) {
    const taggedTimeData = createTotalByTagRenderObj(taggedTime)
    Object.entries(taggedTimeData).forEach(([tag, time]) => {
      tableData.set(tag, time)
    })
  }

  if (tableData.size === 0) {
    return showRegularHoursTooltip ? (
      <Tooltip
        id={id}
        place="bottom"
        delayShow={1000}
        closeOnEsc={true}
        className="z-10"
      >
        <div className="italic">{t('features.approvals.regularHours')}</div>
      </Tooltip>
    ) : null
  }

  return (
    <Tooltip
      id={id}
      place="bottom"
      delayShow={1000}
      closeOnEsc={true}
      className="z-10"
    >
      <TooltipTable data={tableData} />
    </Tooltip>
  )
}
