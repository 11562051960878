import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { FC, ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  columnNameTranslationKey?: TranslationKey
  renderDropdown: () => ReactNode
  renderTrigger: (clickHandler: () => void) => ReactNode
  className?: string
  dropdownWrapperClassName?: string
  clearable?: boolean
  onClear?: () => void
}

export const FilterRenderer: FC<Props> = ({
  className,
  columnNameTranslationKey,
  renderDropdown,
  renderTrigger,
  dropdownWrapperClassName,
  clearable = false,
  onClear,
}) => {
  const { t } = useTranslation()
  const [pickerShown, setPickerShown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(dropdownRef, () => setPickerShown(false))

  return (
    <div
      className={twMerge('flex flex-row', className)}
      onClick={(event) => event.stopPropagation()}
    >
      <div className="relative w-full font-normal" ref={dropdownRef}>
        {renderTrigger(() => setPickerShown(true))}
        {pickerShown && (
          <div
            className={twMerge(
              'absolute left-0 flex flex-col p-4 bg-white rounded-lg shadow top-9',
              dropdownWrapperClassName,
            )}
          >
            {columnNameTranslationKey && (
              <div className="flex mb-2 justify-between items-center">
                <span className="text-sm text-neutral-900 font-semibold">
                  {<>{t(columnNameTranslationKey)}</>}
                </span>

                {clearable && (
                  <span
                    className="text-xs cursor-pointer text-hyperlink-blue"
                    onClick={onClear}
                  >
                    {t('common.clear')}
                  </span>
                )}
              </div>
            )}
            {renderDropdown()}
          </div>
        )}
      </div>
    </div>
  )
}
