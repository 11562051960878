import { FC, useMemo } from 'react'
import {
  useSelectedTasks,
  useSelectTask,
  useDeselectTask,
  useTaskIdsWithEntries,
} from '@features/time-logging/hooks/useTasks'
import { useFormatStartAndEnd } from '@utils/dates'
import { useTranslation } from 'react-i18next'
import { useEmployeeName } from '@features/time-logging/hooks/useEmployeeName'
import { useWeekDates } from '@hooks/useWeekDates'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { TaskPicker } from '@features/task-picker'

interface Props {
  hidden: boolean
  onClose: () => void
}

export const TimeLoggerTaskPicker: FC<Props> = ({ hidden, onClose }) => {
  const userId = useUserId()
  const { start, end } = useWeekDates()
  const selectedTasksQuery = useSelectedTasks()
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const formatStartAndEnd = useFormatStartAndEnd(toLocaleString)
  const disabledTaskIds = useTaskIdsWithEntries(userId)

  const { isSelf, name } = useEmployeeName()

  const title = isSelf
    ? t('features.timeLogging.myWeeklyTasks')
    : t('features.timeLogging.usersWeeklyTasks', { name })

  const selectTaskMutation = useSelectTask()
  const deselectTask = useDeselectTask()

  const handleTaskClick = (task: SelectedTask, checked: boolean) => {
    const mutation = checked ? selectTaskMutation : deselectTask

    mutation.mutate(task)
  }

  const selectedTaskIds = useMemo(() => {
    const ids = new Set<number>()
    const selectedTasks = selectedTasksQuery.data ?? []

    selectedTasks.forEach((task) => {
      ids.add(task.id)
    })

    return ids
  }, [selectedTasksQuery.data])

  return (
    <TaskPicker
      disabledTaskIds={disabledTaskIds}
      hidden={hidden}
      onClose={onClose}
      onTaskClick={handleTaskClick}
      selectedTaskIds={selectedTaskIds}
      subtitle={formatStartAndEnd(start, end)}
      tabs={['my-tasks', 'all-tasks', 'non-project'] as const}
      title={title}
    />
  )
}
