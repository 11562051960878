import { FC, useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  createColumnHelper,
  SortingState,
  ColumnFiltersState,
  CellContext,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowUpAZ } from '@fortawesome/pro-regular-svg-icons'
import { TableFilterField } from 'components/tables/TableFilterField'
import { twMerge } from '@lib/tailwind-merge'
import { ReportStatus } from '../ReportStatus'
import { SavedReport } from '../../types'
import { Link } from 'react-router-dom'
import { InfoDropdownButton } from 'components/buttons/InfoDropdownButton'
import { FilterDetails } from '../FilterDetails'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'

interface Props {
  reports: SavedReport[]
}

const columnHelper = createColumnHelper<SavedReport>()

const reportUrl = (report: SavedReport) => {
  switch (report.type) {
    case 'time_log':
      return `/reporting/time_log_reports/${report.id}`
    case 'audit_log':
      return `/reporting/audit_log_reports/${report.id}`
  }
}

export const Table: FC<Props> = ({ reports }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const toLocaleString = useDateTimeToLocaleString()

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.reporting.reportName'),
      filterFn: 'includesString',
      cell: ({ getValue, row }) => (
        <Link
          to={reportUrl(row.original)}
          className="font-semibold text-hyperlink-blue"
        >
          {getValue()}
        </Link>
      ),
    }),
    columnHelper.group({
      header: t('common.date'),
      columns: [
        columnHelper.accessor('createdAt', {
          header: t('features.reporting.created'),
          cell: ({ getValue }: CellContext<SavedReport, DateTime>) =>
            toLocaleString({
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })(getValue()),
        }),
      ],
    }),
    columnHelper.accessor('owner.name', {
      header: t('features.reporting.createdBy'),
      filterFn: 'equalsString',
    }),
    columnHelper.accessor('description', {
      header: t('features.reporting.description'),
      filterFn: 'includesString',
    }),
    columnHelper.group({
      header: t('features.reporting.reportDetails'),
      columns: [
        columnHelper.accessor('isPublic', {
          header: t('features.reporting.visibility'),
          cell: ({ getValue }) => (
            <ReportStatus status={getValue() ? 'public' : 'private'} />
          ),
        }),
        {
          id: 'filtedApplied',
          header: t('features.reporting.filtersApplied'),
          accessorFn: (report: SavedReport) =>
            Object.keys(report.filters).length,
          cell: ({ getValue, row }) =>
            getValue() > 0 && (
              <InfoDropdownButton label={String(getValue())}>
                <FilterDetails
                  filters={row.original.filters}
                  reportType={row.original.type}
                />
              </InfoDropdownButton>
            ),
        } as ColumnDef<SavedReport, number>,
      ],
    }),
  ]

  const table = useReactTable({
    data: reports,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <div className="px-8 py-6">
      <div className="text-lg mb-4">
        {t('features.reporting.reportCount', {
          count: table.getRowModel().rows.length,
        })}
      </div>
      <table className="w-full text-sm">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(
                (header) =>
                  // Always render the first group (row),
                  // which may contain "placeholders" for ungrouped columns.
                  // When we render the second group (row),
                  // only render grouped columns (ungrouped columns were rendered via their "placeholders" in the first group).
                  (headerGroup.depth === 0 ||
                    header.column.parent !== undefined) && (
                    <th
                      key={header.id}
                      className={twMerge(
                        'p-3 font-semibold text-left align-top border border-t-0 bg-neutral-100 text-neutral-900 border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0',
                        header.column.columns.length === 0 &&
                          'cursor-pointer hover:bg-neutral-200 ',
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                      colSpan={header.colSpan}
                      rowSpan={header.isPlaceholder ? 2 : 1}
                    >
                      <div
                        className={twMerge(
                          header.column.columns.length === 0 &&
                            'flex justify-between',

                          // Center group headings
                          header.column.columns.length > 0 && 'text-center',
                        )}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.columns.length === 0 &&
                          header.column.getIsSorted() && (
                            <FontAwesomeIcon
                              icon={
                                header.column.getIsSorted() === 'asc'
                                  ? faArrowDownAZ
                                  : faArrowUpAZ
                              }
                            />
                          )}
                      </div>
                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField column={header.column} />
                        </div>
                      )}
                    </th>
                  ),
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="hover:bg-neutral-100 group/row">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-4 py-3 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
