import { useTimeCardByDate } from '@features/time-logging/hooks/useTimeCardManagement'
import { col } from '@utils/styles'
import { FC } from 'react'
import { Holiday } from './Holiday'
import { TimeOff } from './TimeOff'
import { Leave } from './Leave'
import { dateIntersectsAbsence, isLeave, isTimeOff } from '@utils/absence'
import { Interval } from 'luxon'
import { twJoin } from 'tailwind-merge'

interface Props {
  date: DateTime
  absences: Absence[]
  holidays: Holiday[]
  onTimeOffClick: () => void
}

type TimeOffAnnotation = {
  duration: number
  type: 'timeOff'
  label: string
}

type LeaveAnnotation = {
  value: Leave
  type: 'leave'
}

type HolidayAnnotation = {
  value: string
  type: 'holiday'
}

type Annotation = TimeOffAnnotation | LeaveAnnotation | HolidayAnnotation

export const DayAnnotation: FC<Props> = ({
  date,
  absences,
  holidays,
  onTimeOffClick,
}) => {
  const isTimeCardPresent = Boolean(useTimeCardByDate(date)?.id)

  const holidayNames = holidays.reduce<string[]>(
    (acc, { startDate, endDate, name: holidayName }) => {
      return Interval.fromDateTimes(startDate, endDate.endOf('day')).contains(
        date,
      )
        ? [...acc, holidayName]
        : acc
    },
    [],
  )

  const annotations: Annotation[] = [
    ...holidayNames.map<HolidayAnnotation>((holidayName) => ({
      value: holidayName,
      type: 'holiday',
    })),
    ...absences
      .filter(
        (absence): absence is Leave =>
          isLeave(absence) && dateIntersectsAbsence(date, absence),
      )
      .map<LeaveAnnotation>((leave) => ({
        value: leave,
        type: 'leave',
      })),
    ...absences
      .filter(
        (absence): absence is TimeOff =>
          isTimeOff(absence) && dateIntersectsAbsence(date, absence),
      )
      .map<TimeOffAnnotation>((timeOff) => ({
        duration: timeOff.duration,
        type: 'timeOff',
        label: timeOff.type,
      })),
  ]

  return (
    <div
      key={`annotations-${date.toString()}`}
      className={twJoin(
        `flex flex-col ${col(date)} space-y-1`,
        isTimeCardPresent && 'justify-end',
      )}
    >
      {annotations.map((annotation, index) => {
        const isLastAnnotation = annotations.length - 1 === index
        const isAttachment = isLastAnnotation && isTimeCardPresent
        const variant = isAttachment ? 'attachment' : 'floating'

        switch (annotation.type) {
          case 'holiday':
            return (
              <Holiday
                name={annotation.value}
                key={`holiday-${annotation.value}`}
                variant={variant}
              />
            )
          case 'timeOff':
            return (
              <TimeOff
                key={`time-off-${annotation.label}-${annotation.duration}`}
                label={annotation.label}
                onClick={onTimeOffClick}
                totalSeconds={annotation.duration}
                variant={variant}
              />
            )
          case 'leave':
            return (
              <Leave
                key={`leave-${annotation.value.type}`}
                onClick={onTimeOffClick}
                variant={variant}
              />
            )
        }
      })}
    </div>
  )
}
