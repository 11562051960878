import { useMemo, useState } from 'react'
import { Checkbox } from 'components/form/checkbox'
import { SearchField } from 'components/form/search-field'
import { useTranslation, TFunction } from 'react-i18next'
import { ButtonInput, FilterRenderer } from '../components'
import { Tabs } from 'components/buttons'

interface GroupType {
  id: string
  label: string
}

type Props<ValueType, IdentifyingType> = {
  accessor: (v: ValueType) => IdentifyingType
  className?: string
  columnNameTranslationKey: TranslationKey
  onChange: (selected: Set<IdentifyingType>) => void
  renderLabel: (
    value: ValueType,
    t: TFunction<'translation', undefined>,
  ) => string
  selected: Set<IdentifyingType>
  values: ValueType[]
  searchable?: boolean
} & (
  | {
      groups: GroupType[]
      getGroupIdForValue: (value: ValueType) => GroupType['id']
    }
  | { groups?: undefined; getGroupIdForValue?: undefined }
)

export const ValuePicker = <
  ValueType,
  IdentifyingType extends { toString: () => string },
>({
  accessor,
  className,
  columnNameTranslationKey,
  onChange,
  renderLabel,
  selected,
  values,
  searchable = false,
  groups,
  getGroupIdForValue,
}: Props<ValueType, IdentifyingType>) => {
  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedGroup, setSelectedGroup] = useState<GroupType['id']>(
    groups?.[0].id ?? '',
  )

  const unselect = (v: ValueType) => {
    const s = new Set(selected)
    s.delete(accessor(v))
    onChange(s)
  }

  const select = (v: ValueType) => {
    onChange(new Set(selected).add(accessor(v)))
  }

  const filteredValues = useMemo(
    () =>
      values.filter((value) =>
        renderLabel(value, t)
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase()),
      ),
    [searchQuery, values, renderLabel, t],
  )

  const valuesForSelectedTab = useMemo(() => {
    // If not grouping, just return all filtered values
    if (!groups) return filteredValues

    return filteredValues.filter(
      (value) => getGroupIdForValue(value) === selectedGroup,
    )
  }, [groups, filteredValues, getGroupIdForValue, selectedGroup])

  return (
    <FilterRenderer
      className={className}
      columnNameTranslationKey={columnNameTranslationKey}
      dropdownWrapperClassName="max-h-96 min-w-[200px] max-w-72"
      clearable={true}
      onClear={() => onChange(new Set())}
      renderDropdown={() => (
        <div className="flex flex-col overflow-hidden">
          {groups && (
            <Tabs
              tabs={groups}
              activeTab={selectedGroup}
              onTabClick={setSelectedGroup}
              className="mb-4"
              variant="full-width-tabs"
            />
          )}

          {searchable && (
            <SearchField
              onChange={setSearchQuery}
              value={searchQuery}
              placeholder={t('common.search')}
              className="max-w-full mb-4"
            />
          )}

          <div className="flex flex-col overflow-auto grow">
            {valuesForSelectedTab.map((value) => {
              const v = accessor(value)
              const isChecked = selected.has(v)

              const handleChange = () => {
                const operation = isChecked ? unselect : select
                operation(value)
              }

              return (
                <label
                  key={v.toString()}
                  className="flex flex-row items-center py-2 font-normal whitespace-nowrap cursor-pointer"
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {renderLabel(value, t)}
                </label>
              )
            })}
          </div>
        </div>
      )}
      renderTrigger={(clickHandler) => (
        <ButtonInput onClick={clickHandler} selectedCount={selected.size} />
      )}
    />
  )
}
