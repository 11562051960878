import { FC } from 'react'
import { UnsubmittedBody } from './UnsubmittedBody'
import { SubmittedBody } from './SubmittedBody'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  timeCard: TimeCard
  inFuture: boolean
  totalAllocatedInSeconds: number
  isValid: boolean
  className: string
  scheduledWorkHours?: string
}

export const Body: FC<Props> = ({
  timeCard,
  inFuture,
  totalAllocatedInSeconds,
  isValid,
  className,
  scheduledWorkHours,
}) => {
  return (
    <div className={twMerge('grow flex flex-col mx-2', className)}>
      {timeCard.submitted ? (
        <SubmittedBody
          timeCard={timeCard}
          totalAllocatedInSeconds={totalAllocatedInSeconds}
        />
      ) : (
        <UnsubmittedBody
          timeCard={timeCard}
          inFuture={inFuture}
          totalAllocatedInSeconds={totalAllocatedInSeconds}
          isValid={isValid}
          scheduledWorkHours={scheduledWorkHours}
        />
      )}
    </div>
  )
}
