import { FC, useState } from 'react'
import { TaskEstimateMode } from 'types'
import { EtcAdjustment } from './EtcAdjustment'
import { EtcHistory } from './EtcHistory'

interface Props {
  disableSave: boolean
  estimated?: number // seconds
  estimateMode: TaskEstimateMode
  heading: string
  logged?: number // seconds
  onCancel: () => void
  onSave: (value: number /* seconds */) => void
  subHeading?: string
  subtitle?: string
  taskId: number
  title: string
  useTaskEstimates?: (
    taskId: number,
    options?: { enabled: boolean },
  ) => {
    isLoading: boolean
    data: TaskEstimate[] | undefined
  }
}

export const EtcCard: FC<Props> = ({
  disableSave,
  estimated,
  estimateMode,
  heading,
  logged,
  onCancel,
  onSave,
  subHeading,
  subtitle,
  taskId,
  title,
  useTaskEstimates,
}) => {
  const [showing, setShowing] = useState<'adjustment' | 'history'>('adjustment')

  return (
    <div className="flex flex-col w-[360px] rounded-lg shadow border border-neutral-200 mb-10">
      <div className="flex flex-col bg-neutral-100 px-3 py-2 text-neutral-900 gap-1">
        <div className="flex flex-row text-sm">
          <span className="font-semibold">{title}</span>
          {subtitle && <span className="ml-1">({subtitle})</span>}
        </div>
        {subHeading && (
          <div className="flex flex-row items-center text-xs text-neutral-600 font-medium">
            {subHeading}
          </div>
        )}
        {heading && (
          <div className="flex flex-row items-center text-sm font-semibold">
            {heading}
          </div>
        )}
      </div>
      <EtcAdjustment
        disableSave={disableSave}
        estimated={estimated}
        estimateMode={estimateMode}
        hidden={showing !== 'adjustment'}
        logged={logged}
        onCancel={onCancel}
        onHistoryOpen={() => setShowing('history')}
        onSave={onSave}
      />
      <EtcHistory
        estimated={estimated}
        estimateMode={estimateMode}
        hidden={showing !== 'history'}
        onHistoryClose={() => setShowing('adjustment')}
        taskId={taskId}
        useTaskEstimates={useTaskEstimates}
      />
    </div>
  )
}
