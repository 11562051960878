import { Timeline } from 'components/timeline'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getTimelineItemForEvent } from 'utils/auditEvents'

interface Props {
  events: ApprovalEventLogEntry[]
}

export const ApprovalHistory: FC<Props> = ({ events }) => {
  const { t } = useTranslation()

  const sortedEvents = useMemo(
    () =>
      events?.sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1
        if (a.timestamp > b.timestamp) return -1
        return 0
      }),
    [events],
  )

  if (events.length === 0)
    return (
      <span className="mt-3 text-center italic text-sm">
        {t('features.approvals.noHistory')}
      </span>
    )

  return (
    <Timeline
      timeline={sortedEvents.map((event) => getTimelineItemForEvent(event, t))}
    />
  )
}
