import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { ChangeApprovalGroupForm } from '../components/ChangeApprovalGroupForm'
import { useTranslation } from 'react-i18next'
import { Employee } from '../types'

export const useChangeApprovalGroup = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const changeApprovalGroup = useCallback(
    (employee: Employee) => {
      const id = modal.form({
        title: t('features.changeApprovalGroup.editApprovalGroupTitle'),
        content: (
          <ChangeApprovalGroupForm
            onDone={() => modal.close(id)}
            employee={employee}
          />
        ),
      })
    },
    [modal, t],
  )

  return { changeApprovalGroup }
}
