import { Login } from '@features/login'
import { Logout } from '@features/logout'
import { LoginError } from '@features/login-error'

export const unauthenticatedRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login_error',
    element: <LoginError />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
]
