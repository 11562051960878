import { Checkbox } from 'components/form/checkbox'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type Props = {
  checked: boolean
  disabled?: boolean
  contigentWorkerType: 'vendor' | 'contractor' | 'temporary' | null
  indeterminate: boolean
  linkPath?: string
  name: string
  onChange: (checked: boolean) => void
  payType: 'salary' | 'hourly' | null
  title?: string | null
}

export const EmployeeCell: FC<Props> = ({
  checked,
  disabled = false,
  contigentWorkerType,
  indeterminate,
  linkPath,
  name,
  onChange,
  payType,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <div className="px-4">
      <div className="flex flex-row items-center w-60 2xl:w-72">
        <Checkbox
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate && !checked}
          onChange={() => onChange(!checked)}
        />
        <div className="flex flex-col ml-2 overflow-hidden grow">
          <span className="text-sm font-semibold truncate text-neutral-900">
            {linkPath ? <Link to={linkPath}>{name}</Link> : name}
          </span>
          <span className="text-xs truncate">
            {title}
            {title && <span className="mx-1 text-xs text-neutral-400">•</span>}
            {payType && t(`payTypes.${payType}`)}
            {contigentWorkerType &&
              t(`contingentWorkerTypes.${contigentWorkerType}`)}
          </span>
        </div>
      </div>
    </div>
  )
}
