import { deduplicateIntervals, filterOutUndefinedIntervals } from './intervals'
import { DateTime } from 'luxon'

type NormalizedBreak = Break & {
  startAt: number
  endAt: number
}

const getNormalizedBreaks = (
  timeCard: Pick<TimeCard, 'startAt' | 'endAt' | 'breaks'>,
): NormalizedBreak[] => {
  const { startAt, endAt } = timeCard
  if (startAt === null) return []
  if (endAt === null) return []

  const validBreaks = filterOutUndefinedIntervals(timeCard.breaks)
    .filter((b) => b.startAt <= endAt && b.endAt >= startAt)
    .map((b) => ({
      ...b,
      startAt: b.startAt > startAt ? b.startAt : startAt,
      endAt: b.endAt < endAt ? b.endAt : endAt,
    }))

  return deduplicateIntervals(validBreaks)
}

export const totalBreaksInSeconds = (
  timeCard: Pick<TimeCard, 'startAt' | 'endAt' | 'breaks'>,
): number => {
  const breaks = getNormalizedBreaks(timeCard)
  return breaks.reduce((sum, b) => b.endAt - b.startAt + sum, 0)
}

export const totalWorkedInSeconds = (
  timeCard: Pick<TimeCard, 'startAt' | 'endAt' | 'breaks'>,
): number => {
  if (timeCard.endAt === null) return 0
  if (timeCard.startAt === null) return 0

  return timeCard.endAt - timeCard.startAt - totalBreaksInSeconds(timeCard)
}

export const totalEntriesDuration = (
  entries: Pick<Entry, 'duration'>[],
): number => {
  return entries.reduce((sum, entry) => sum + entry.duration, 0)
}

// NOTE: These validations should be kept in sync
//       with TimeCard#valid_for_submission? in the API
export const timeCardValidForSubmission = ({
  timeCard,
  entries,
  totalTimeOffInSeconds,
  onLeave,
  scheduledWorkHours,
}: {
  timeCard: Pick<TimeCard, 'startAt' | 'endAt' | 'breaks' | 'id' | 'date'>
  entries: Pick<Entry, 'duration'>[]
  totalTimeOffInSeconds: number
  onLeave: boolean
  scheduledWorkHours?: string // undefined == not scheduled to work
}): boolean => {
  // NullTimeCards are automatically valid in this context
  if (timeCard.id === 0) return true

  // No need to continue if TC is missing start/end time
  if (timeCard.startAt == null || timeCard.endAt == null) return false

  const inFuture = timeCard.date > DateTime.now()
  const totalWorked = totalWorkedInSeconds(timeCard)
  const workedHours = totalWorked > 0
  const allocatedAllHours = totalEntriesDuration(entries) === totalWorked
  const exceedsTimeOffCap =
    scheduledWorkHours !== undefined &&
    totalTimeOffInSeconds > 0 &&
    totalWorked + totalTimeOffInSeconds >
      parseFloat(scheduledWorkHours) * 60 ** 2

  // Breaks are valid if they have start/end times and do not fall
  // outside of the configured work day
  const breaksAreValid = timeCard.breaks.every(
    (br) =>
      br.startAt != null &&
      br.endAt != null &&
      // This is not reachable if the TC is missing start/end times
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      br.startAt >= timeCard.startAt! &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      br.endAt <= timeCard.endAt!,
  )

  return (
    !inFuture &&
    workedHours &&
    allocatedAllHours &&
    !exceedsTimeOffCap &&
    breaksAreValid &&
    !onLeave
  )
}

export const getTitleKey = (
  timeCard: Pick<TimeCard, 'submitted' | 'fullyApproved'>,
) => {
  if (timeCard.fullyApproved) return 'common.approved'
  if (timeCard.submitted) return 'common.submitted'

  return 'common.open'
}
