import { FC, useState } from 'react'
import { useUpdateEmployeeApprovalGroup } from '../hooks/useUpdateEmployeeApprovalGroup'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { Button } from 'components/buttons'
import { Label } from 'components/form/label'
import { ApprovalGroupSelector } from './ApprovalGroupSelector'
import { InfoCard } from 'components/containers/info-card'
import { Employee } from '../types'

interface Props {
  employee: Employee
  onDone: () => void
}

export const ChangeApprovalGroupForm: FC<Props> = ({ onDone, employee }) => {
  const { t } = useTranslation()
  const updateEmployeeApprovalGroup = useUpdateEmployeeApprovalGroup()
  const [newApprovalGroup, setNewApprovalGroup] = useState<
    Employee['approvalGroup']
  >(employee.approvalGroup)
  const [reviewing, setReviewing] = useState(false)

  const employeeName = 'name' in employee ? employee.name : employee.fullName

  const save = async () => {
    try {
      await updateEmployeeApprovalGroup.mutateAsync({
        workdayWorkerId: employee.workdayWorkerId,
        approvalGroupId: newApprovalGroup.id,
      })

      onDone()

      toast({
        title: t('features.changeApprovalGroup.approvalGroupUpdated'),
        variant: 'success',
        content: t(
          'features.changeApprovalGroup.successfullyUpdatedApprovalGroup',
          {
            employeeName,
            approvalGroupName: newApprovalGroup.name,
          },
        ),
      })
    } catch (error) {
      toast({
        title: t('features.changeApprovalGroup.unableToUpdateApprovalGroup'),
        variant: 'error',
        content: t('features.changeApprovalGroup.failedToUpdateApprovalGroup', {
          employeeName,
        }),
      })
    }
  }

  return (
    <div>
      <div className="p-6 pt-0 flex flex-col gap-4 max-w-lg">
        <div className="grid grid-cols-2 gap-y-4">
          <Label>{t('common.employeeName')}</Label>
          <div className="text-sm">{employeeName}</div>

          <Label>
            {t('features.changeApprovalGroup.currentApprovalGroup')}
          </Label>
          <div className="text-sm">{employee.approvalGroup.name}</div>

          {reviewing && (
            <>
              <Label>
                {t('features.changeApprovalGroup.newApprovalGroup')}
              </Label>
              <div className="text-sm">{newApprovalGroup.name}</div>
            </>
          )}
        </div>

        {reviewing ? (
          <InfoCard variant="info">
            {t('features.changeApprovalGroup.pendingApprovalsWillBeReRouted')}
          </InfoCard>
        ) : (
          <ApprovalGroupSelector
            selected={newApprovalGroup}
            onChange={setNewApprovalGroup}
          />
        )}
      </div>
      <div className="flex border-t border-neutral-300 py-4 px-6 w-full justify-end">
        {reviewing ? (
          <>
            <Button
              variant="subtle"
              className="font-normal"
              onClick={() => setReviewing(false)}
            >
              {t('common.back')}
            </Button>

            <Button
              onClick={() => void save()}
              loading={updateEmployeeApprovalGroup.isLoading}
              disabled={updateEmployeeApprovalGroup.isLoading}
            >
              {t('common.save')}
            </Button>
          </>
        ) : (
          <>
            <Button variant="subtle" className="font-normal" onClick={onDone}>
              {t('common.cancel')}
            </Button>

            <Button
              onClick={() => setReviewing(true)}
              disabled={employee.approvalGroup.id === newApprovalGroup.id}
            >
              {t('features.changeApprovalGroup.review')}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
