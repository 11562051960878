import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'

interface Props {
  className?: string
  timeline: TimelineItem[]
}

export const Timeline: FC<Props> = ({ className, timeline }) => {
  const toLocaleString = useDateTimeToLocaleString()
  const localizeMonth = toLocaleString({ month: 'short' })
  const localizeDay = toLocaleString({ day: 'numeric' })

  return (
    <div className={twMerge('flex flex-col px-8 py-2', className)}>
      {timeline.map((lineItem, index, arr) => {
        const isLast = index === arr.length - 1

        return (
          <div className="flex flex-row" key={lineItem.date.toISOTime()}>
            <div className="flex flex-col items-center p-1 mr-4 font-medium text-neutral-900">
              <span className="text-xs">{localizeMonth(lineItem.date)}</span>
              <span className="text-2xl">{localizeDay(lineItem.date)}</span>
              <div
                className={twMerge(
                  'mt-2 w-[2px] bg-neutral-300 grow',
                  isLast && 'hidden',
                )}
              ></div>
            </div>
            <div className="flex flex-col p-4 mb-6 border rounded border-neutral-200 bg-neutral-100 grow">
              <div className="flex flex-row items-start justify-between">
                {lineItem.title}
                <span className="text-xs font-medium text-neutral-500 whitespace-nowrap">
                  {lineItem.date.toFormat('t')}
                </span>
              </div>
              {lineItem.content && (
                <span className="mt-3">{lineItem.content}</span>
              )}
              <p className="italic mt-3 text-xs">{lineItem.subtext}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
