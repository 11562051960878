import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'components/loaders'
import { FC, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { twJoin, twMerge } from 'tailwind-merge'
import { calculateWidths } from './utils/calculateWidths'
import { renderDaysHours } from './utils/renderDuration'

interface Props {
  className?: string
  disableEstimate?: boolean
  etc?: number // seconds
  estimateSuffix?: string
  logged?: number // seconds
  showLoading?: boolean
}

export const Bars: FC<Props> = ({
  className,
  disableEstimate,
  etc,
  estimateSuffix = '',
  logged,
  showLoading,
}) => {
  const { t } = useTranslation()
  const ttlTooltipId = useId()
  const etcTooltipId = useId()

  if (logged === undefined || showLoading)
    return <Spinner className="mx-auto" />

  const widths = calculateWidths({ etc, logged })

  return (
    <div className={twMerge('flex flex-row text-xs w-full gap-x-1', className)}>
      {logged >= 0 && (
        <div
          className={twMerge(
            'flex flex-row justify-center items-center bg-primary-600 min-w-fit text-white border border-primary-400 rounded-l px-1',
            widths.logged >= 100 && 'rounded-r',
          )}
          style={{ width: `${widths.logged}%` }}
          data-tooltip-id={ttlTooltipId}
        >
          {renderDaysHours(logged)}
          <Tooltip id={ttlTooltipId} place="top" delayShow={1000}>
            {t('components.estimatedTimeToCompletion.totalTimeLogged')}
          </Tooltip>
        </div>
      )}
      {etc === undefined ? (
        <div
          className="flex flex-row justify-center items-center bg-warning-100 min-w-fit rounded-r border border-warning-300 px-1"
          style={{ width: `${widths.etc}%` }}
          data-tooltip-id={etcTooltipId}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-warning-900"
          />
          <Tooltip id={etcTooltipId} place="top" delayShow={1000}>
            {t('components.estimatedTimeToCompletion.noEstimateSet')}
          </Tooltip>
        </div>
      ) : (
        (etc > 0 || (etc == 0 && logged == 0)) && (
          <div
            className={twJoin(
              'flex flex-row justify-center items-center min-w-fit rounded-r border px-1',
              !disableEstimate
                ? 'bg-white border-neutral-400'
                : 'bg-neutral-200 border-neutral-300 text-neutral-500',
              widths.etc >= 100 && 'rounded-l',
            )}
            style={{ width: `${widths.etc}%` }}
            data-tooltip-id={etcTooltipId}
          >
            {renderDaysHours(etc)}
            {estimateSuffix}
            <Tooltip id={etcTooltipId} place="top" delayShow={1000}>
              {t('components.estimatedTimeToCompletion.estimateToComplete')}
            </Tooltip>
          </div>
        )
      )}
    </div>
  )
}
