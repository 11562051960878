import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'
import { TaskEstimateMode } from 'types'
import { Bars } from './Bars'
import { renderDaysHoursMinutes } from './utils/renderDuration'
import { parse } from './utils/parse'
import { IncrementDecrementButtons } from 'components/time-entry-table/IncrementDecrementButtons'
import { EntryInput } from 'components/time-entry-table/EntryInput'
import { Button } from 'components/buttons'
import { EtcCardFooter } from './EtcCardFooter'

const ONE_DAY = 8 * 60 * 60

interface Props {
  disableSave: boolean
  estimated?: number // seconds
  estimateMode: TaskEstimateMode
  hidden: boolean
  logged?: number // seconds
  onCancel: () => void
  onHistoryOpen: () => void
  onSave: (value: number /* seconds */) => void
}

export const EtcAdjustment: FC<Props> = ({
  disableSave,
  estimated,
  estimateMode,
  hidden,
  logged,
  onCancel,
  onHistoryOpen,
  onSave,
}) => {
  const { t } = useTranslation()
  const [updatedEstimate, setUpdatedEstimate] = useState(
    renderDaysHoursMinutes(
      estimated === undefined ? undefined : estimated - (logged ?? 0),
    ),
  )

  if (hidden) return null

  const parsed = parse(updatedEstimate)
  const isValid =
    estimateMode !== TaskEstimateMode.FULL ||
    (parsed !== undefined && parsed >= 0)

  const increment = () => {
    let newTime = parsed ?? 0

    // If current value is evenly divisible by a whole day,
    // increase by 1 day, otherwise round up to the nearest day
    if (newTime % ONE_DAY === 0) {
      newTime += ONE_DAY
    } else {
      newTime = Math.ceil(newTime / ONE_DAY) * ONE_DAY
    }

    setUpdatedEstimate(renderDaysHoursMinutes(newTime))
  }

  const decrement = () => {
    let newTime = parsed ?? 0

    // If current value is evenly divisible by a whole day,
    // decrease by 1 day, otherwise round down to the nearest day
    if (newTime % ONE_DAY === 0) {
      newTime -= ONE_DAY
    } else {
      newTime = Math.floor(newTime / ONE_DAY) * ONE_DAY
    }

    setUpdatedEstimate(renderDaysHoursMinutes(Math.max(newTime, 0)))
  }

  return (
    <>
      <div className="flex flex-col p-4 gap-y-2">
        <div className="text-xs text-neutral-600 font-medium">
          {t('components.estimatedTimeToCompletion.taskProgress')}
        </div>
        <Bars
          disableEstimate={estimateMode !== TaskEstimateMode.FULL}
          etc={isValid ? parsed : undefined}
          estimateSuffix={
            estimateMode === TaskEstimateMode.BURN_DOWN_ONLY ? '*' : ''
          }
          logged={logged}
        />
        <div className="text-xs text-neutral-600 font-medium mt-1">
          {t('components.estimatedTimeToCompletion.estimateToComplete')}
        </div>
        <div className="flex flex-row items-center">
          <div
            className={twJoin(
              'flex flex-row w-3/4 p-2 mr-2 border border-neutral-200 rounded-lg',
              estimateMode !== TaskEstimateMode.FULL && 'bg-neutral-150',
            )}
          >
            <IncrementDecrementButtons
              disabled={
                estimateMode !== TaskEstimateMode.FULL
                  ? 'both'
                  : (parsed ?? 0) <= 0
                  ? 'decrement'
                  : undefined
              }
              onIncrement={increment}
              onDecrement={decrement}
            />
            <EntryInput
              disabled={estimateMode !== TaskEstimateMode.FULL}
              placeHolder={t(
                'features.timeLogging.estimatePlaceholder',
              ).toString()}
              className="outline-none"
              onIncrement={increment}
              onDecrement={decrement}
              value={updatedEstimate}
              setValue={setUpdatedEstimate}
            />
          </div>
        </div>
      </div>
      <EtcCardFooter
        showDisabledEtcMessage={estimateMode !== TaskEstimateMode.FULL}
        historyShown={false}
        onHistoryClick={onHistoryOpen}
        warning={
          estimateMode !== TaskEstimateMode.FULL && estimated === undefined
        }
      >
        <Button onClick={onCancel} variant="text">
          {t('common.cancel')}
        </Button>
        <Button
          onClick={() => {
            if (!isValid) return
            if (parsed === undefined) return

            onSave(parsed)
          }}
          disabled={!isValid || disableSave}
        >
          {t('common.save')}
        </Button>
      </EtcCardFooter>
    </>
  )
}
