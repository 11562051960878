import {
  faCalendarStar,
  faCloudCheck,
  faTreePalm,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode, useId } from 'react'
import { Tooltip } from 'react-tooltip'
import { twMerge } from '@lib/tailwind-merge'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { twJoin } from 'tailwind-merge'

type Props = {
  children: ReactNode
  className?: string
  leftAnnotations?: ReactNode
  lighten?: boolean
  rightAnnotations?: ReactNode
  showLeaveIcon?: boolean
  showIssuesIcon?: boolean
  showHolidayIcon?: boolean
  showNotesIcon?: boolean
  showSentIcon?: boolean
} & ({ tooltipContent?: ReactNode } | { tooltip?: (id: string) => ReactNode })

export const CellAnnotation: FC<Props> = ({
  children,
  className,
  leftAnnotations,
  lighten = false,
  rightAnnotations,
  showLeaveIcon = false,
  showIssuesIcon = false,
  showHolidayIcon = false,
  showNotesIcon = false,
  showSentIcon = false,
  ...props
}) => {
  const tooltipId = useId()

  return (
    <div
      className={twMerge('relative flex flex-col h-full', className)}
      data-tooltip-id={tooltipId}
    >
      <div className={twJoin('flex flex-col h-full', lighten && 'opacity-60')}>
        {children}
        <div className="absolute flex flex-row justify-between top-1 left-1 right-1 gap-1">
          {leftAnnotations}
          {showNotesIcon && (
            <FontAwesomeIcon
              icon={faComment}
              size="sm"
              fixedWidth={true}
              className="text-neutral-400"
            />
          )}
          <div className="flex ml-auto gap-1">
            {showLeaveIcon && (
              <FontAwesomeIcon
                icon={faTreePalm}
                size="sm"
                fixedWidth={true}
                className="text-neutral-400"
              />
            )}
            {showHolidayIcon && (
              <FontAwesomeIcon
                icon={faCalendarStar}
                size="sm"
                fixedWidth={true}
                className="text-neutral-400"
              />
            )}
            {showIssuesIcon && (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size="sm"
                className="text-error-500"
                fixedWidth={true}
              />
            )}
            {showSentIcon && (
              <FontAwesomeIcon
                icon={faCloudCheck}
                size="sm"
                fixedWidth={true}
              />
            )}
            {rightAnnotations}
          </div>
        </div>
      </div>
      {'tooltipContent' in props && (
        <Tooltip
          id={tooltipId}
          place="bottom"
          delayShow={1000}
          closeOnEsc={true}
          className="z-10"
        >
          {props.tooltipContent}
        </Tooltip>
      )}
      {'tooltip' in props && props.tooltip && props.tooltip(tooltipId)}
    </div>
  )
}
