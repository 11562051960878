import { Column } from '@tanstack/react-table'
import { FC } from 'react'
import { TableTextFilterField } from './TableTextFilterField'
import { TableSelectFilterField } from './TableSelectFilterField'
import { useTranslation } from 'react-i18next'

interface Props {
  column: Column<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  inputClassName?: string
}

export const TableFilterField: FC<Props> = ({ column, inputClassName }) => {
  const { t } = useTranslation()

  switch (column.columnDef.filterFn) {
    case 'includesString':
      // TODO: Placeholder should be specified on column def/keyed to column ID if we ever add multiple text-search columns
      return (
        <TableTextFilterField
          column={column}
          inputClassName={inputClassName}
          placeholder={t('components.tables.tableFilterField.search')}
        />
      )
    case 'equalsString': {
      const values: string[] = [
        ...new Set(
          column
            .getFacetedRowModel()
            .rows.map((r) => r.getValue<string | undefined | null>(column.id))
            .filter((v) => v) // Remove undefined/null
            .sort() as string[],
        ),
      ]

      return <TableSelectFilterField values={values} column={column} />
    }
  }

  return <></>
}
