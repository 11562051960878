import { Splash } from 'components/layout'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginWithGoogle } from './LoginWithGoogle'
import { LoginWithOIDC } from './LoginWithOIDC'

export const Login: FC = () => {
  const { t } = useTranslation()

  const authMechanism = import.meta.env.VITE_AUTH_MECHANISM

  return (
    <Splash
      heading={t('features.login.welcomeBack')}
      subHeading={t('features.login.signInToGetStarted')}
    >
      <div className="flex flex-col mt-8">
        {authMechanism === 'oidc' ? <LoginWithOIDC /> : <LoginWithGoogle />}
      </div>
    </Splash>
  )
}
