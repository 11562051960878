import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import { useMemo } from 'react'

/**
 * yyyyMMd and toLowerCaseDay are used in a programmatic sense.  They're not to be
 * displayed to humans.  For that, you'll want to leverage useDateTimeToLocaleString
 */
export const yyyyMMdd = (date: DateTime) => date.toFormat('yyyy-MM-dd')
export const toLowerCaseDay = (date: DateTime) =>
  date.toFormat('EEE').toLowerCase()

const defaultFn = (args: DateTimeFormatOptions) => (date: DateTime) =>
  date.toLocaleString(args)

export const useFormatStartAndEnd = (
  toLocaleString: ReturnType<typeof useDateTimeToLocaleString> = defaultFn,
) => {
  return useMemo(() => {
    const formatDay = (date: DateTime) =>
      toLocaleString({ day: 'numeric' })(date)
    const formatMonth = (date: DateTime) =>
      toLocaleString({ month: 'short' })(date)
    const formatMonthAndDay = (date: DateTime) =>
      `${formatMonth(date)} ${formatDay(date)}`

    const formatStartAndEnd = (start: DateTime, end: DateTime) => {
      return start.hasSame(end, 'month')
        ? `${formatMonthAndDay(start)}-${formatDay(end)}`
        : `${formatMonthAndDay(start)} - ${formatMonthAndDay(end)}`
    }

    return formatStartAndEnd
  }, [toLocaleString])
}

export const getDayOfWeek = (date: DateTime): DayOfWeek => {
  const weekday = date.get('weekday')

  switch (weekday) {
    case 1:
      return 'monday'
    case 2:
      return 'tuesday'
    case 3:
      return 'wednesday'
    case 4:
      return 'thursday'
    case 5:
      return 'friday'
    case 6:
      return 'saturday'
    case 7:
      return 'sunday'
    default:
      throw new Error(`Invalid day of week: ${weekday}`)
  }
}

export const getWeekStartAsSunday = (date: DateTime) => {
  if (date.get('weekday') === 7) return date.startOf('day')

  return date.startOf('week').minus({ day: 1 })
}
