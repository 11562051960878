import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  faClockRotateLeft,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'

type Props = {
  children?: React.ReactNode
  showDisabledEtcMessage: boolean
  historyShown: boolean
  onHistoryClick: () => void
  warning: boolean
}

export const EtcCardFooter: FC<Props> = ({
  children,
  showDisabledEtcMessage,
  historyShown,
  onHistoryClick,
  warning,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={twJoin(
        'flex flex-row justify-end px-4 gap-x-2 border-t border-neutral-200 h-12 items-center',
        warning && 'bg-warning-100',
      )}
    >
      <button
        className="flex flex-row items-center text-neutral-600 font-medium text-xs mr-auto"
        onClick={() => onHistoryClick()}
      >
        <FontAwesomeIcon
          icon={historyShown ? faTimes : faClockRotateLeft}
          className="mr-1"
        />
        {historyShown
          ? t('components.estimatedTimeToCompletion.exitHistory')
          : t('components.estimatedTimeToCompletion.history')}
      </button>
      {showDisabledEtcMessage ? (
        <span
          className={twJoin(
            'text-xs font-medium flex flex-row gap-1 items-center',
            warning ? 'text-warning-900' : 'text-neutral-600',
          )}
        >
          {warning ? (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-warning-900"
            />
          ) : (
            '*'
          )}
          {t('components.estimatedTimeToCompletion.etcsCanOnlyBeSetInShotgrid')}
        </span>
      ) : (
        children
      )}
    </div>
  )
}
