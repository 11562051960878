import { updateEmployeeApprovalGroup } from '@lib/api'
import { employeesKeys, usersKeys } from '@lib/keys'
import { useQueryClient, useMutation } from '@tanstack/react-query'

export const useUpdateEmployeeApprovalGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      workdayWorkerId,
      approvalGroupId,
    }: {
      workdayWorkerId: number
      approvalGroupId: number
    }) => updateEmployeeApprovalGroup(workdayWorkerId, approvalGroupId),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(employeesKeys.all)
        void queryClient.invalidateQueries(usersKeys.all)
      },
    },
  )
}
