import {
  faChevronDown,
  faChevronRight,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSet } from '@hooks/useSet'
import {
  FC,
  CSSProperties,
  ReactNode,
  forwardRef,
  RefObject,
  Fragment,
  useId,
} from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { Tooltip } from '@lib/tooltip'

type Content = { content: ReactNode } | { icon?: IconDefinition; label: string }

type ParentItem = {
  disabled?: boolean
  children: Item[]
} & Content

export type Item = {
  onClick?: () => void
  disabled?: boolean
  tooltip?: ReactNode

  // Pass href to render an anchor tag instead of a button
  href?: string
  target?: string
} & Content

export interface DropdownMenuProps {
  className?: string
  hidden: boolean
  items: (ParentItem | Item)[]
  onItemClick: () => void
  onMouseLeave?: () => void
  preventEventPropagation?: boolean
  ref?: RefObject<HTMLDivElement>
  style?: CSSProperties
  header?: string
  beforeItems?: ReactNode
  itemTag?: ItemProps['as']
}

function isParentItem(item: ParentItem | Item): item is ParentItem {
  return 'children' in item
}

interface ItemProps {
  as?: keyof HTMLElementTagNameMap
  children: ReactNode
  className?: string
  disabled?: boolean
  href?: string
  onClick?: () => void
  preventEventPropagation: boolean
  target?: string
  tooltip?: ReactNode
}

const Item: FC<ItemProps> = ({
  as = 'button',
  children,
  className,
  disabled,
  href,
  onClick,
  preventEventPropagation,
  target,
  tooltip,
}) => {
  const Tag = href ? 'a' : as
  const id = useId()

  return (
    <>
      <Tag
        data-tooltip-id={id}
        className={twMerge(
          'flex flex-row items-center px-6 text-sm font-normal border-b group/menu-item h-11 whitespace-nowrap text-neutral-900 border-neutral-200 last:border-none hover:bg-neutral-100',
          className,
          disabled &&
            'text-neutral-400 cursor-not-allowed bg-neutral-200 hover:bg-neutral-200',
        )}
        disabled={disabled}
        href={disabled ? undefined : href}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
        onMouseDown={(event) =>
          preventEventPropagation && event.stopPropagation()
        }
        onTouchStart={(event) =>
          preventEventPropagation && event.stopPropagation()
        }
        target={href ? target : undefined}
      >
        {children}
      </Tag>
      <Tooltip id={id} delayShow={100} place="top">
        {tooltip}
      </Tooltip>
    </>
  )
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      hidden,
      beforeItems,
      items,
      onItemClick,
      onMouseLeave,
      preventEventPropagation = false,
      className,
      style,
      header,
      itemTag,
    },
    ref,
  ) => {
    const {
      set: expandedItems,
      add: expandItem,
      remove: collapseItem,
    } = useSet<number>()

    if (hidden) return null

    return (
      <div
        className={twMerge(
          'absolute right-0 z-10 flex flex-col bg-white border rounded-lg drop-shadow-lg border-neutral-300 min-w-[140px]',
          className,
        )}
        onMouseLeave={onMouseLeave}
        style={style}
        ref={ref}
      >
        {header && (
          <div className="px-6 py-3 text-xs font-medium uppercase border-b bg-neutral-100 text-neutral-500 border-neutral-200">
            {header}
          </div>
        )}
        {beforeItems}
        {items.map((item, index) => {
          const isExpanded = isParentItem(item) && expandedItems.has(index)

          const handleItemClick = (item: { onClick?: () => void }) => {
            item.onClick?.()
            onItemClick()
          }

          const handleRootItemClick = () => {
            if (isParentItem(item)) {
              const operation = isExpanded ? collapseItem : expandItem
              operation(index)
            } else {
              handleItemClick(item)
            }
          }

          const renderContent = (item: Content) => {
            if ('content' in item) return item.content

            return (
              <>
                {item.icon && (
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="mr-2 text-neutral-600 group-disabled/menu-item:text-neutral-400"
                    fixedWidth
                  />
                )}
                {item.label}
              </>
            )
          }

          return (
            <Fragment key={`menu-item-key-${index}`}>
              <Item
                as={itemTag}
                disabled={item.disabled}
                href={isParentItem(item) ? undefined : item.href}
                key={`menu-item-${index}`}
                onClick={handleRootItemClick}
                preventEventPropagation={preventEventPropagation}
                target={isParentItem(item) ? undefined : item.target}
                tooltip={isParentItem(item) ? undefined : item.tooltip}
              >
                {renderContent(item)}
                {isParentItem(item) && (
                  <FontAwesomeIcon
                    className="pl-3 ml-auto text-neutral-600"
                    icon={isExpanded ? faChevronDown : faChevronRight}
                  />
                )}
              </Item>
              {isParentItem(item) &&
                expandedItems.has(index) &&
                item.children.map((childItem, index) => (
                  <Item
                    key={`menu-child-item-${index}`}
                    className="bg-neutral-50"
                    onClick={() => handleItemClick(childItem)}
                    disabled={childItem.disabled}
                    href={childItem.href}
                    target={childItem.target}
                    preventEventPropagation={preventEventPropagation}
                    tooltip={childItem.tooltip}
                  >
                    {renderContent(childItem)}
                  </Item>
                ))}
            </Fragment>
          )
        })}
      </div>
    )
  },
)

DropdownMenu.displayName = 'DropdownMenu'
