import { SideColumn, SideCell } from 'components/time-entry-table'
import { TaskCell } from './cells/TaskCell'

interface Props {
  taskIdsWithEntries: Set<number>
  hoverY: number
  tasks: SelectedTask[]
}

export function TaskNameColumn(props: Props) {
  const tasks = props.tasks

  return (
    <SideColumn side="left">
      {tasks.map((task, index) => (
        <SideCell
          key={`LeftColumnCell${task.id}`}
          rowIndex={index + 1}
          highlight={index + 1 == props.hoverY}
        >
          <TaskCell
            taskIdsWithEntries={props.taskIdsWithEntries}
            key={`task_cell_${task.id}`}
            task={task}
          />
        </SideCell>
      ))}
    </SideColumn>
  )
}
